<template>
  <div>
    <work-summary
      :work-summary="workSummary"
      :this-week-duration="thisWeekDuration"
      :this-month-duration="thisMonthDuration"
      :processing="processing"
    />

    <b-skeleton-wrapper
      :loading="loadingGanttChart"
      class="mb-2"
    >
      <template #loading>
        <b-skeleton-img
          no-aspect
          height="180px"
        />
      </template>

      <table
        class="table table-striped"
      >
        <tr
          v-for="day of paginatedWorkPatterns"
          :key="day.day"
        >
          <td class="m-0 p-0">
            <b-card>
              <gantt-chart
                :start-end-date-time="day"
                :work-patterns="workPatterns.filter(chart => chart.day === day.date )"
              />
              <div
                v-if="paginatedWorkPatterns.length"
                class="d-flex pt-2"
              >
                <b-badge variant="light-success">
                  {{ $t('Active Time') }}
                </b-badge>
                <b-badge
                  variant="light-warning"
                  class="ml-2"
                >
                  {{ $t('Manual Time') }}
                </b-badge>
                <b-badge
                  variant="light-danger"
                  class="ml-2"
                >
                  {{ $t('Deleted Time') }}
                </b-badge>
              </div>
            </b-card>
          </td>
        </tr>
      </table>
    </b-skeleton-wrapper>

    <b-pagination
      v-if="daysRangeForWorkPattern.length > 1"
      :value="1"
      :total-rows="daysRangeForWorkPattern.length"
      :per-page="ganttChartPerPage"
      align="right"
      @input="(page) => paginatePattern(page)"
    />
    <recent-screencasts
      :screen-casts="screenCasts"
      :processing="processing"
      :date-range="dateRange"
    />
    <div
      v-if="hasMoreActivityPages"
      class="d-flex justify-content-center pb-2"
    >
      <a
        class="btn btn-outline-primary"
        target="_blank"
        :href="`/screen-casts?start_date=${FORMAT_DATE(dateRange.start)}&end_date=${FORMAT_DATE(dateRange.end)}&user_id=${userId}`"
      >
        Load More
      </a>
    </div>
    <b-row>
      <b-col md="6">
        <b-tabs>
          <b-tab
            :title="$t('Productive')"
            class="mb-1"
          >
            <activity-by-type
              type="PRODUCTIVE"
              :table-data="productiveActivityByType"
              :per-page="perPage"
              :is-processing="isProcessingByRecordType"
              @onPaginationChange="getActivityByType"
            />
          </b-tab>
        </b-tabs>
      </b-col>
      <b-col
        md="6"
        class="pb-1"
      >
        <b-tabs>
          <b-tab :title="$t('Unproductive')">
            <activity-by-type
              type="UNPRODUCTIVE"
              :table-data="unproductiveActivityByType"
              :per-page="perPage"
              :is-processing="isProcessingByRecordType"
              @onPaginationChange="getActivityByType"
            />
          </b-tab>
          <b-tab :title="$t('Neutral')">
            <activity-by-type
              type="NEUTRAL"
              :table-data="neutralActivityByType"
              :per-page="perPage"
              :is-processing="isProcessingByRecordType"
              @onPaginationChange="getActivityByType"
            />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import useApollo from '@/plugins/graphql/useApollo'
import {
  BTabs, BTab, BSkeletonWrapper, BSkeletonImg, BCard, BBadge, BPagination,
} from 'bootstrap-vue'
import GanttChart from '@/views/common/components/GanttChartTime.vue'
import WorkSummary from './WorkSummary.vue'
import RecentScreencasts from './RecentScreencasts.vue'
import ActivityByType from '../ActivityByTypes.vue'

export default {
  components: {
    WorkSummary,
    RecentScreencasts,
    ActivityByType,
    BTabs,
    BTab,
    GanttChart,
    BSkeletonWrapper,
    BSkeletonImg,
    BCard,
    BBadge,
    BPagination,
  },
  props: {
    dateRange: {
      type: Object,
      required: true,
    },
    userId: {
      type: [Object, String],
      default: '',
    },
    timeZone: {
      type: [String],
      default: '',
    },
    thisWeekDuration: {
      type: Number,
      default: 0,
    },
    thisMonthDuration: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      processing: false,
      screenCasts: [],
      workSummary: {
        totalDuration: 0,
        productiveDuration: 0,
        unproductiveDuration: 0,
        neutralDuration: 0,
        manualDuration: 0,
      },
      productiveActivityByType: { data: [], total: 0 },
      unproductiveActivityByType: { data: [], total: 0 },
      neutralActivityByType: { data: [], total: 0 },
      perPage: 3,
      activityPageNumber: 1,
      hasMoreActivityPages: false,
      isProcessingByRecordType: {},
      ganttChartRange: {},
      workPatterns: [],
      loadingGanttChart: false,
      daysRangeForWorkPattern: [],
      paginatedWorkPatterns: [],
      ganttChartPerPage: 1,
    }
  },
  watch: {
    dateRange() {
      this.getUserActivities()
    },
    userId() {
      this.getUserActivities()
    },
    timeZone() {
      this.getUserActivities()
    },
  },
  created() {
    this.getUserActivities()
  },
  methods: {
    getUserActivities(page = 1) {
      this.processing = true
      useApollo.activities
        .getUserActivities({
          startTime: this.dateRange.start,
          endTime: this.dateRange.end,
          keyword: this.$store.state.project.selectedProject,
          userId: this.userId,
          first: 20,
          page,
        })
        .then(response => {
          if (page === 1) {
            this.screenCasts = []
          }
          let requiredScreenShots = []
          const screenCastsData = response.data.user
            ? response.data.user
            : response.data.me
          this.activityPageNumber = screenCastsData.activityRecords.currentPage
          this.hasMoreActivityPages = screenCastsData.activityRecords.hasMorePages
          if (response.data.user) {
            requiredScreenShots = screenCastsData.activityRecords.data
          } else {
            const screenCasts = screenCastsData.activityRecords.data
            requiredScreenShots = screenCasts
          }
          this.workSummary = {
            totalDuration: screenCastsData.recordsDuration,
            productiveDuration: screenCastsData.productiveDuration,
            unproductiveDuration: screenCastsData.unproductiveDuration,
            neutralDuration: screenCastsData.neutralDuration,
            manualDuration: screenCastsData.manualDuration,
          }
          const newScreenCasts = [...this.screenCasts, ...requiredScreenShots]
          this.screenCasts = newScreenCasts
        })
        .catch(err => {
          this.showErrorMessage(err)
        })
        .finally(() => {
          this.processing = false
        })

      this.getActivityByType()
    },
    getActivityByType(page = 1, activityType = null) {
      const getTopUses = activity => activity.data
      let requiredTypes = [
        { label: 'PRODUCTIVE', variable: 'productiveActivityByType' },
        { label: 'UNPRODUCTIVE', variable: 'unproductiveActivityByType' },
        { label: 'NEUTRAL', variable: 'neutralActivityByType' },
      ]
      if (activityType) {
        this.isProcessingByRecordType = {
          ...this.isProcessingByRecordType,
          [activityType]: true,
        }
        requiredTypes = requiredTypes.filter(rt => rt.label === activityType)
      }
      requiredTypes.forEach(type => {
        useApollo.activities
          .getActivityByType({
            keyword: this.$store.state.project.selectedProject,
            page,
            first: this.perPage,
            productivity: type.label,
            uuid: this.userId,
            startTime: this.dateRange.start,
            endTime: this.dateRange.end,
          })
          .then(response => {
            this[type.variable] = {
              data: getTopUses(response.data.me.topUses),
              total: response.data.me.topUses.total,
            }
          }).finally(() => {
            if (activityType) {
              this.isProcessingByRecordType = {
                ...this.isProcessingByRecordType,
                [activityType]: false,
              }
            }
          })
      })
      this.getDailyActivity()
    },
    getDailyActivity() {
      this.loadingGanttChart = true
      useApollo.activities.getWorkPattern({
        startTime: this.dateRange.start,
        endTime: this.dateRange.end,
        projectId: this.$store.state.project.selectedProject,
        userId: this.userId,
      }).then(response => {
        this.ganttChartRange = {
          start: this.dateRange.start,
          end: this.dateRange.end,
        }
        const daysBetween = this.GET_DAY_BETWEEN_DAYS(this.dateRange.start, this.dateRange.end)
        this.daysRangeForWorkPattern = daysBetween.reverse()
        const activitiesData = response.data.me.activities.data
        activitiesData.sort((a, b) => new Date(a.startedAt) - new Date(b.startedAt))
        this.workPatterns = activitiesData.map(ranges => ({
          ...ranges,
          day: this.FORMAT_DATE(ranges.startedAt, 'YYYY-MM-DD'),
        }))
        // console.log(this.workPatterns)
        this.paginatePattern(1)
      }).finally(() => { this.loadingGanttChart = false })
    },
    paginatePattern(page) {
      const pageSize = this.ganttChartPerPage
      this.paginatedWorkPatterns = this.daysRangeForWorkPattern.slice((page - 1) * pageSize, page * pageSize)
    },
  },
}
</script>
