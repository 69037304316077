<template>
  <div class="home position-relative">
    <info-status-alert />
    <filters
      v-model="form"
      :date-type="dateType"
      @timezone-changed="updateTimezone"
    >
      <template v-slot:disabledData>
        <div class="text-center w-100">
          <h3 v-if="activeTab === 0">
            {{ FORMAT_DATE(todayDate.start,COMMON.DATE_FORMAT) }}
          </h3>
          <h3
            v-if="activeTab === 1"
            style="margin-top: 8px;"
          >
            {{ FORMAT_DATE(yesterday.start, COMMON.DATE_FORMAT) }}
          </h3>
          <h3
            v-if="activeTab === 2"
            style="margin-top: 8px;"
          >
            {{ FORMAT_DATE(todayWeeks.start, COMMON.DATE_FORMAT) }} - {{ FORMAT_DATE(todayWeeks.endDate, COMMON.DATE_FORMAT) }}
          </h3>
          <h3
            v-if="activeTab === 3"
            style="margin-top: 8px;"
          >
            {{ FORMAT_DATE(todayMonth.start, COMMON.DATE_FORMAT) }} - {{ FORMAT_DATE(todayMonth.endDate, COMMON.DATE_FORMAT) }}
          </h3>
        </div>
      </template>
    </filters>
    <b-tabs
      id="tour-monitor-users"
      v-model="activeTab"
      pills
    >
      <b-tab
        :title="$t('Today')"
        lazy
        @click="refreshToday"
      >
        <day-view
          id="day-view"
          ref="todayView"
          :key="getUserId"
          class="pt-1"
          :date-range="todayDate"
          :this-week-duration="thisWeekDuration"
          :user-id="getUserId"
          :this-month-duration="thisMonthDuration"
          :time-zone="getTimeZone"
        />
      </b-tab>
      <b-tab
        :title="$t('Yesterday')"
        lazy
      >
        <day-view
          :key="getUserId"
          class="pt-1"
          :date-range="yesterday"
          :this-week-duration="thisWeekDuration"
          :this-month-duration="thisMonthDuration"
          :user-id="getUserId"
          :time-zone="getTimeZone"
        />
      </b-tab>
      <b-tab
        :title="$t('Past 7 days')"
        lazy
      >
        <multiple-day-view
          :key="getUserId"
          :date-range="todayWeeks"
          :user-id="getUserId"
          :time-zone="getTimeZone"
        />
      </b-tab>
      <b-tab
        :title="$t('Past 30 days')"
        lazy
      >
        <multiple-day-view
          :key="getUserId"
          :date-range="todayMonth"
          :user-id="getUserId"
          :time-zone="getTimeZone"
        />
      </b-tab>
      <b-tab
        :title="$t('Date')"
        lazy
      >
        <day-view
          :key="getUserId"
          class="pt-1"
          :date-range="singleDate"
          :this-week-duration="thisWeekDuration"
          :user-id="getUserId"
          :this-month-duration="thisMonthDuration"
          :time-zone="getTimeZone"
        />
      </b-tab>
      <b-tab
        :title="$t('Date Range')"
        lazy
      >
        <multiple-day-view
          :key="getUserId"
          :date-range="dateRange"
          :user-id="getUserId"
          :time-zone="getTimeZone"
        />
      </b-tab>
    </b-tabs>

  </div>
</template>

<script>
import {
  BTab, BTabs,
} from 'bootstrap-vue'
import useApollo from '@/plugins/graphql/useApollo'
import Filters from '@/views/common/components/Filter.vue'
import { mapActions, mapGetters } from 'vuex'
import { getUserData } from '@/auth/utils'
import DayView from './Dashboard/SingleDay/Index.vue'
import MultipleDayView from './Dashboard/MultipleDay/index.vue'
import {
  checkComponentReady, continueTour, isActiveTourStep, setComponentReady,
} from './tour/tour'
import { memberTourIndexes, ownerTourIndexes } from './tour/tourConstants'
import InfoStatusAlert from './common/components/InfoStatusAlert.vue'

export default {
  components: {
    BTab,
    BTabs,
    Filters,
    DayView,
    MultipleDayView,
    InfoStatusAlert,
  },
  data() {
    return {
      self: getUserData(),
      form: {
        date: this.$route.query.date ?? (this.TODAY({ localTime: false })),
        time_zone: localStorage.timezone ?? 'Asia/Kathmandu',
        userId: this.$route.query.userId ?? null,
        start_date: this.TODAY({ localTime: false }),
        end_date: this.TODAY({ localTime: false }),
      },
      thisWeekDuration: 0,
      thisMonthDuration: 0,
      activeTab: this.$route.query.date ? 4 : 0,
      today: this.TODAY({ localTime: false }),
    }
  },
  computed: {
    ...mapGetters('project', ['GET_SELECTED_USER']),
    getUserId() {
      return this.form.userId
    },
    getTimeZone() {
      return this.form.time_zone
    },
    singleDate() {
      return {
        start: `${this.form.date} 00:00:00`,
        end: this.ADD_DAYS(this.form.date, 1, 'YYYY-MM-DD 00:00:00').start,
      }
    },
    todayDate() {
      return {
        start: `${this.today} 00:00:00`,
        end: this.ADD_DAYS(this.today, 1, 'YYYY-MM-DD 00:00:00').start,
      }
    },
    yesterday() {
      const interval = this.YESTERDAY(this.today)
      return {
        start: interval.start,
        end: this.ADD_DAYS(interval.end, 1, 'YYYY-MM-DD 00:00:00').start,
      }
    },
    dateRange() {
      return {
        start: `${this.form.start_date} 00:00:00`,
        end: this.ADD_DAYS(this.form.end_date, 1, 'YYYY-MM-DD 00:00:00').start,
      }
    },
    todayWeeks() {
      const interval = this.SUBTRACT_DAYS(this.today, 6, 'YYYY-MM-DD HH:mm:ss')
      return {
        start: interval.start,
        endDate: interval.end,
        end: this.ADD_DAYS(interval.end, 1, 'YYYY-MM-DD 00:00:00').start,
      }
    },
    todayMonth() {
      const interval = this.SUBTRACT_DAYS(this.today, 29, 'YYYY-MM-DD HH:mm:ss')
      return {
        start: interval.start,
        endDate: interval.end,
        end: this.ADD_DAYS(interval.end, 1, 'YYYY-MM-DD 00:00:00').start,
      }
    },
    dateType() {
      if (this.activeTab === 4) {
        return 'single'
      } if (this.activeTab === 5) {
        return 'range'
      }
      return 'hidden'
    },
    isOwner() {
      const ownerRole = this.self.companyRoles.data.find(companyRole => companyRole.role.name === 'owner')
      return ownerRole ? 'owner' : 'member'
    },
  },
  watch: {
    GET_SELECTED_USER: {
      handler(val) {
        if (this.$route.query.userId) this.setSelectedUser(this.$route.query.userId)
        else if (val && val !== this.form.userId) this.form.userId = val
      },
      immediate: true,
    },
    getUserId(val) {
      this.getMonthWeekDuration()
      if (val !== this.GET_SELECTED_USER) this.setSelectedUser(val)
    },
    form: {
      handler(newValue) {
        this.storeFilterQuery('dashboard-filer', { ...newValue })
        this.getMonthWeekDuration()
        this.today = this.TODAY({ localTime: false })
      },
      deep: true,
    },
  },

  async created() {
    this.getMonthWeekDuration()

    if (this.$store.state.tour.isTouringPaused) {
      await checkComponentReady()
      continueTour({ step: this.isOwner ? ownerTourIndexes.TOUR_USER_DROPDOWN : memberTourIndexes.TOUR_USER_DROPDOWN })
    }
  },
  methods: {
    ...mapActions('project', ['setSelectedUser']),
    getRecordDuration({ startTime, endTime }) {
      return useApollo.activities
        .getDurations({
          startTime,
          endTime,
          keyword: localStorage.selected_project,
          uuid: this.getUserId,
        })
    },
    getMonthWeekDuration() {
      const week = this.INTERVAL_IN_WEEKS(this.TODAY('YYYY-MM-DD 00:00:00'))
      const month = this.INTERVAL_IN_MONTH(this.TODAY('YYYY-MM-DD 00:00:00'))
      this.getRecordDuration({
        startTime: week.start,
        endTime: week.end,
      }).then(response => {
        this.thisWeekDuration = response.data.me.recordsDuration
      }).finally(() => {
        if (this.checkTourStep) {
          setComponentReady()
        }
      })

      this.getRecordDuration({
        startTime: month.start,
        endTime: month.end,
      }).then(response => {
        this.thisMonthDuration = response.data.me.recordsDuration
      })
    },
    refreshToday() {
      if (this.$refs.todayView) {
        this.$refs.todayView.getUserActivities()
        this.getMonthWeekDuration()
      }
    },
    updateTimezone() {
      if (this.isAfterTODAY(this.form.date)) this.form.date = this.TODAY({ localTime: false })
      if (this.isAfterTODAY(this.form.start_date)) this.form.start_date = this.TODAY({ localTime: false })
      if (this.isAfterTODAY(this.form.end_date)) this.form.end_date = this.TODAY({ localTime: false })
    },
    checkTourStep() {
      return isActiveTourStep(ownerTourIndexes.TOUR_PERMISSIONS_FORM) || isActiveTourStep(ownerTourIndexes.TOUR_HOURS_TRACKED) || isActiveTourStep(memberTourIndexes.TOUR_CHANGE_PROJECT) || isActiveTourStep(memberTourIndexes.TOUR_HOURS_TRACKED)
    },
  },
}
</script>

<style lang="scss">
@media screen and (max-width: 768px) {
  .nav-pills .nav-link {
    padding: 0.5rem 1rem;
    margin-bottom: 8px;
  }
}
</style>
